import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';

import logo from '../assets/logo.png';
import leo from '../assets/leo.gif';
import { DISCORD, TWITTER } from '../store';

import { IoLogoTwitter, IoLogoFacebook, IoLogoDiscord} from "react-icons/io5";


export default function HeaderBis(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
      <Navbar collapseOnSelect expand="lg" variant="dark"
        style={{fontWeight: "bolder", backgroundColor: "#D9D9D9"}}>
        <Container style={{zIndex: 1}}>
          <Modal centered show={show} onHide={handleClose} size="sm">
            <Modal.Header closeButton>
              <Modal.Title>facebook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <img
              src={leo}
              width="100%"
              height="100%"
              alt="logo"
              />
            </Modal.Body>
          </Modal>
          <Navbar.Brand className='text-black fs-5' onClick={() => props.setView('home')}>
            <img
              src={logo}
              width="80"
              height="80"
              className="d-inline-block"
              alt="logo"
              />
            {' '}Belugas Big Brain
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="mx-auto"
              >
              <Nav.Link className='text-black fs-6 me-2' href="#team">About</Nav.Link>
              <Nav.Link className='text-black fs-6 me-2' onClick={() => props.setView('vision')}>Vision</Nav.Link>
              <Nav.Link className='text-black fs-6 me-2' onClick={() => props.setView('contact')}>Contact</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link onClick={handleShow}><IoLogoFacebook className='only-full mx-2' color="black" size={45}/></Nav.Link>
              <Nav.Link href={DISCORD}><IoLogoTwitter className='only-full mx-2' color="black" size={45}/></Nav.Link>
              <Nav.Link href={TWITTER}><IoLogoDiscord className='only-full mx-2' color="black" size={45}/></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}