import { useState } from 'react';

import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Roadmap from './components/Roadmap';
import Contact from './components/Contact';
import Frame from './components/Frame';
import Faq from './components/Faq';
import Team from './components/Team';
import Community from './components/Community';
import HeaderBis from './components/HeaderBis';
import Vision from './components/Vision';



export default function App() {

  const [view, setView] = useState('home');

  return (
    <>
      <div id="#home">
        { (view === 'home')
          ? <>
              <Header setView={setView}/>
              <Home/> 
            </>
          : <></> }
        { (view === 'contact')
          ? <>
              <HeaderBis setView={setView}/>
              <Contact/>
            </>
          : <></>}
        { (view === 'vision')
          ? <>
              <HeaderBis setView={setView}/>
              <Vision/>
            </>
          : <></>}
      </div>
      <div id='neon-yellow'/>
      <div id='neon-blue'/>
      <div id='neon-lightblue'/>
      <Roadmap/>
      <Frame/>
      <Faq/>
      <Frame/>
      <Team/>
      <Community/>
      <Footer/>
    </>
    
  );
}