import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import hero from "../assets/hero.png";
import accent from "../assets/accent.png";
import { DISCORD, YELLOW } from '../store';

const variant = {
  visible: { opacity: 1, transition:{duration: 10} },
  hidden: { opacity: 0, scale: 1, transition:{duration: 2}},
}

export default function Home() {

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div id="home" className='page'>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <motion.img
              ref={ref}
              style={{maxWidth: "90vw"}}
              variants={variant}
              initial="hidden"
              animate={control}
              src={hero}
              alt="hero"
            />
          </Col>
          <Col md="auto">
            <img src={accent} alt="accent"/>
            <h1 className='title'>It's your brain,</h1>
            <h1 className='title'>It's time to save it.</h1>
            <h5 className='subtitle'>An NFT brand dedicated to education, elegance,</h5>
            <h5 className='subtitle'>and personal development.</h5>
            <Button
              href={DISCORD}
              className='fw-bold fs-5 p-4 px-5 mt-4'
              style={{
                background: "transparent",
                borderRadius: "50px",
                borderWidth: "3px",
                borderColor: YELLOW,
              }}
              >Join us here
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

//