
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

import { IoChevronDownCircle, IoChevronUpCircle } from "react-icons/io5";

import { YELLOW } from '../store';
import { useState } from 'react';

export default function Faq() {

    const [question, setQuestion] = useState(null);

    const changeQuestion = (id) => {
        if (question === id) {
            setQuestion(null)
        } else {
            setQuestion(id)
        }
    }

    return (
    <div id="faq" className='page'>
        <Container className='text-center'>
            <h1 className='smalltitle mt-5'>Frequently Asked Questions (FAQs)</h1>
            <Accordion>
                <Accordion.Item eventKey="0" onClick={() => changeQuestion(0)}>
                    <Accordion.Header>
                        <span className='pe-5'>What is Belugas Big Brain ?</span>
                        { question === 0
                        ? <IoChevronDownCircle className='faq-arrow' color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>Beluga's Big Brain is a project of 5000 NFTs and a brand dedicated to education, men's elegance, and personal development.</p> 
                        <p>Our goal is to bring together a community around values such as openness and independence of spirit, sharing, humility, and self-improvement in order to better understand, apprehend, and adapt to the changing world around us.</p>
                        <p>With the emergence of artificial intelligence, the acquisition of versatile skills will collectively enable us to better adapt to the profound changes that lie ahead. We want to unite our community through the quality of our content, our vision, and by bringing value to each of our members. <strong>Adopting a Beluga is adopting a lifestyle.</strong></p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" onClick={() => changeQuestion(1)}>
                    <Accordion.Header><span className='pe-5'>Why create a project through NFTs ?</span>
                    { question === 1
                        ? <IoChevronDownCircle className='faq-arrow' color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>Because the web3 community is a community that is open-minded, curious, passionate, and where boundaries do not exist.</p> 
                        <p>These are already many points that we share with what we want to create with Belugas Big Brain. 
                        We think that this avant-garde community deserves a project that lives up to its expectations to help it structure and develop.</p>
                        <p>NFTs respond to a quest for independence that is dear to our project, where we promote independence of spirit in particular.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" onClick={() => changeQuestion(2)}>
                    <Accordion.Header><span className='pe-5'>What is the vision of the Founder for the project ?</span>
                    { question === 2
                        ? <IoChevronDownCircle className='faq-arrow' color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p> My goal is to work full-time on this project, which corresponds to my personal aspirations. I like to educate myself in fields that interest me and I like to share and transmit knowledge. I think that this work and time can be useful for you. I want to prove to you that it is not necessary to know everything about a subject to take ownership of it and take action. I like to do things properly, even if it takes the necessary time to do so. Here, quality takes precedence over quantity.</p> 
                        <p><strong>Belugas Education</strong> will allow us to collectively learn and develop skills in all areas of life, whether it is personal development, business, fashion, etc.</p>
                        <p>The <strong>Belugas Focus On</strong> section deals in detail with a part of a broader topic, such as real estate investment, for example.</p>
                        <p>There will also be a <strong>Belugas DIY</strong> section for those who are interested in cultivating a certain form of independence, which could involve making a shower gel, for example, or an NFT. Big Brain, as they say.</p>
                        <p>I would like each of you to create and/or develop your full potential: education, creation of business groups, help and support etc.</p>
                        <p>As for the rest, it all depends on you. But my vision is very clear. In a world of instant gratification, fast fashion, junk food, cancel culture, insults, we have a responsibility to offer those who want it a quality in our actions that matches our ambitions.</p>
                        <p>Bringing meaning back, promoting craftsmanship, quality, doing less but better. Using our Big Brain to do useful things for ourselves and for our loved ones.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" onClick={() => changeQuestion(3)}>
                    <Accordion.Header><span className='pe-5'>And in the long term ?</span>
                    { question === 3
                        ? <IoChevronDownCircle className='faq-arrow' color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>Thanks to the content we will create, particularly on topics that affect everyone such as health, we will be able to offer, for example, an easily accessible and comprehensive book for the general public. It will be under the name of the Belugas Big Brain collective and will be used as a gateway to discover our project, NFTs, and more broadly web3. In a way, success is inevitable as long as quality for the benefit of community members remains at the heart of the project.</p> 
                        <p>Furthermore, we would like to create partnerships with other projects or brands that share our values and can benefit our holders. This will be done on a case-by-case basis but with one golden rule: nothing but the best.</p>
                        <p>Finally, if the project becomes sustainable and successful, then we can dream of designing high-quality physical/digital products exclusively for community members with the goal of creating a unique and premium consumer experience.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" onClick={() => changeQuestion(4)}>
                    <Accordion.Header><span className='pe-5'>What is the price and the blockchain ?</span>
                    { question === 4
                        ? <IoChevronDownCircle className='faq-arrow' color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>TBA. Belugas adoption blockchain is Polygon.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" onClick={() => changeQuestion(5)}>
                    <Accordion.Header><span className='pe-5'>Isn't it limiting to share educational content only with a small group of holders ?</span>
                    { question === 5
                        ? <IoChevronDownCircle className='faq-arrow'  color={YELLOW} size={60}/>
                        : <IoChevronUpCircle className='faq-arrow' color={YELLOW} size={60}/>
                        }
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>Yes, and that is why the Belugas Education section will be intended to be shared more easily with members who wish to join our community. While other projects remain closed to a limited number of owners, Belugas wants to conquer the world.</p> 
                        <p>Of course, the owners will always have exclusive content and will remain at the heart of the project. But it is also important to reach out to as many people as possible in order to grow our community, which will inevitably benefit the NFT holders.</p>
                        <p>To illustrate my point, and as an example, we can offer a free mint of a unique NFT that holders can give to the person of their choice. This NFT will allow access to the Beluga Education section. It is a kind of social adoption.</p>
                        <p>Some summary content will be shared for free on different social networks in order to develop our community by creating effective and engaging content.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>

    </div>

  );
}

