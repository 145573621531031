import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import logoHeader from '../assets/logoHeader.png';
import leo from '../assets/leo.gif';
import { DISCORD, TWITTER } from '../store';

import { IoLogoTwitter, IoLogoFacebook, IoLogoDiscord} from "react-icons/io5";

export default function Footer() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" variant="dark"
        style={{fontWeight: "bolder"}}>
        <Container style={{zIndex: 1}}>
          <Modal centered show={show} onHide={handleClose} size="sm">
            <Modal.Header closeButton>
              <Modal.Title>facebook</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <img
              src={leo}
              width="100%"
              height="100%"
              alt="logo"
              />
            </Modal.Body>
          </Modal>
          <Navbar.Brand className='text-white fs-5' href="#home">
            <img
              src={logoHeader}
              width="80"
              height="80"
              className="d-inline-block"
              alt="logo"
              />
          </Navbar.Brand>
            <Nav
              className="mx-auto"
              >
              <Nav.Link className='text-white fs-6 me-2' href="#home">@2023 BelugasBigBrain Inc. - All Rights Reserved</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link onClick={handleShow}><IoLogoFacebook className='mx-2' color="white" size={45}/></Nav.Link>
              <Nav.Link href={DISCORD}><IoLogoTwitter className='mx-2' color="white" size={45}/></Nav.Link>
              <Nav.Link href={TWITTER}><IoLogoDiscord className='mx-2' color="white" size={45}/></Nav.Link>
            </Nav>
            
        </Container>
      </Navbar>
      <Container>
        <div className='mt-5' style={{border: "1px solid #FFFFFF"}}/>
        <Row className='mt-5 mb-5' xs={1} sm={3}>
          <Col>
            <p><a className="footer" href="#home">Terms & Conditions</a></p>
            <p><a className="footer" href="#home">Privacy Policy</a></p>
            <p><a className="footer" href="#team">About</a></p>
          </Col>
          <Col>
            <p><a className="footer" href="#contact">Contact</a></p>
            <p><a className="footer" href="#vision">Vision</a></p>
            <p><a className="footer" href="#home">Home</a></p>
          </Col>
          <Col>
            <p className='fw-bold ps-3 pt-2'>Join our newsletter</p>
            <InputGroup 
              style={{
                  backgroundColor: "#4B11AD",
                  borderRadius: "40px"}}>
              <Form.Control
                style={{ borderRadius: "40px" }}
                placeholder="Email address"
                aria-label="Email address"
                aria-describedby="basic-addon2"
              />
              <Button
                href="mailto:skranf@belugasbigbrain.com"
                className='px-3 fw-bold'
                style={{borderRadius: "40px", backgroundColor: "#4B11AD", border: "none" }}
                id="button-addon2">
                SUBMIT
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <div className='frame-line'/>
    </div>
  );
}