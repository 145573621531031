import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { DISCORD, YELLOW, BLACK } from '../store';

export default function Community(){
    return (
        <div className="page">
            <Container className='community text-center'>
                <h5 className='subtitle pt-5 text-black pb-2'>YOUR BRAIN IS READY ?</h5>
                <h1 className='title'>Be a Part Of</h1>
                <h1 className='title'>Our Community</h1>
                <Button
                    href={DISCORD}
                    className='fw-bold fs-5 p-4 px-5 mt-5'
                    style={{
                        background: YELLOW,
                        borderRadius: "50px",
                        borderWidth: "3px",
                        borderColor: YELLOW,
                        color: BLACK
                    }}
                    >Join Our Discord
                </Button>
            </Container>
        </div>
    )
}