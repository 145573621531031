import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import hero from "../assets/hero.png";

const variant = {
  visible: { opacity: 1, transition:{duration: 10} },
  hidden: { opacity: 0, scale: 1, transition:{duration: 2}},
}

export default function Vision() {

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <div id="contact" className='page' style={{backgroundColor: "#D9D9D9"}}>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <motion.img
              ref={ref}
              style={{maxWidth: "90vw"}}
              variants={variant}
              initial="hidden"
              animate={control}
              src={hero}
              alt="hero"
            />
          </Col>
          <Col className='my-5 text-black'>
            <p>This project is important for several reasons. It advocates for an alternative form of education to the traditional education we all know. An education that places the individual and values such as self-improvement, excellence, humility, sharing and respect at the heart of the project. The goal is to learn collectively about topics essential to everyday life that are not addressed in school or in mainstream media.</p>
            <p>The aim is to make people aware tha many of our actions and behaviors are the result of our education, but above all of our lack of education. To summarize my thinking, the more we educate ourselves, the more aware we are and the more responsibilities we have. The responsibility for our choices, our actions, for ourselves, for our loved ones, because once we know, we can no longer hide. Many people are trapped in a mold that does not allow them to develop their potential, to give meaning their actions, and to find their place in this world simply because they are not even aware that is possible.</p>
            <p>In a society that is losing cultural value, meaning, the value of things, where everything is accelerating at the expense of the overall quality of exchanges, consumer products, clothing, etc., it is essential to allow people who refuse this state of affairs to come together and share different values that are becoming increasingly rare nowadays.</p>
            <p>That’s why i created this project. Rather than spending $1,000 on training on this that subject, you’ll spend a few dollars to access a huge amount of educational and quality content on various topics. Of course, not everything will be done in few days, but it is the assurance of obtaining value regularly over the long term. I wish and especially hope not to have to create content alone, and that Big Brains will also share their knowledge so that we collectively become a better version of ourselves.</p>
            <p>The sharing of knowledge and experiences saves a lot of time and has an invaluable value. Later on, we can establish partnerships with brands that share our values and benefit our holders and grow our community via classic social networks.</p>
            <p>To conclude, i think this project can be a fantastic showcase for the web3 community by promoting and defending values that resonate in everyone’s hearts, far from clichés and preconceptions about NFTs and cryptocurrencies more generally.</p>
            <p>Mr. Frost</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}