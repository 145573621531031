import { useState } from 'react';
import { motion } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import pp0 from "../assets/pp0.png";
import pp1 from "../assets/pp1.png";
import pp2 from "../assets/pp2.png";
import pp3 from "../assets/pp3.png";
import pp4 from "../assets/pp4.png";



function View(props){

    const sentence = {
        hidden: {opacity:1},
        visible:{
            opacity:1,
            transition: {
                delay: 1,
                staggerChildren: 0.08,
            }
        }
    }
    
    const letter = {
        hidden: {opacity:0, y:50},
        visible: {opacity:1, y:0},
    }

    return(
        <motion.span
        className="load-screen--message"
        variants={sentence}
        initial="hidden"
        animate="visible">
            {props.text.split("").map((char, index) => {
                return(
                    <motion.span key={char+"-" +index} variants={letter}>{char}</motion.span>
                )
            })}
        </motion.span>
    )
}


function Phase1(){

    return(
        <Row  className='align-items-center mt-5'>
            <Col sm={10}>
                <h1 className='smalltitle pt-5 ps-4'>Belugas Adoption</h1>
                <ul className='mt-3'>
                    <li className='text grey'>Launch all social platforms (Discord, Twitter, Website)</li>
                    <li className='text grey'>Grow our community</li>
                    <li className='text grey'>Mint launches</li>
                </ul>
            </Col>
            <Col sm={2} className='q text-center'>
                <View text="Q2"/>
            </Col>
        </Row>
    );
}

function Phase2(){
    return(
        <Row  className='align-items-center mt-5'>
            <Col sm={10}>
                <h1 className='smalltitle pt-5 ps-4'>Community oriented</h1>
                <ul className='mt-3'>
                    <li className='text grey'><i>Belugas Education</i> launch for holders</li>
                    <li className='text grey'>Invest time and money, if needed, in community projects</li>
                    <li className='text grey'>Community events (#HelpABeluga, business groups)</li>
                    <li className='text grey'><i>Belugas Focus On</i> launch</li>
                    <li className='text grey'>Free content for all</li>
                </ul>
            </Col>
            <Col sm={2} className='q text-center'>
            <View text="Q3"/>
            </Col>
        </Row>
    );
}

function Phase3(){
    return(
        <Row  className='align-items-center mt-5'>
            <Col sm={10}>
                <h1 className='smalltitle pt-5 ps-4'>Branding Expansion</h1>
                <ul className='mt-3'>
                    <li className='text grey'><i>Belugas DIY</i> launch</li>
                    <li className='text grey'><i>Belugas Toolbox</i> launch</li>
                    <li className='text grey'>Web2 brand and Web3 project partnerships</li>
                </ul>
            </Col>
            <Col sm={2} className='q text-center'>
                <View text="Q4"/>
            </Col>
        </Row>
    );
}

function Phase4(){
    return(
        <Row  className='align-items-center mt-5'>
            <Col sm={9}>
                <h1 className='smalltitle pt-5 ps-4'>Mass adoption</h1>
                <ul className='mt-3'>
                    <li className='text grey'>App launch</li>
                    <li className='text grey'>Social platforms launch (TikTok, YouTube, Instagram)</li>
                    <li className='text grey'>Book creation</li>
                </ul>
            </Col>
            <Col sm={3} className='q text-center'>
                <View text="Q1 2024"/>
            </Col>
        </Row>
    );
}

function Phase5(){
    return(
        <Row  className='align-items-center mt-5'>
            <Col sm={9}>
                <h1 className='smalltitle pt-5 ps-4'>Conquer the world</h1>
                <ul className='mt-3'>
                    <li className='text grey'>Release Belugas Big Brain Roadmap 2.0</li>
                </ul>
            </Col>
            <Col sm={3} className='q text-center'>
                <View text="2024"/>
            </Col>
        </Row>
    );
}



export default function Roadmap() {



    const [phase, setPhase] = useState(1);

    return (
    <div id="roadmap" className='page'>
        <Container>
        
            <div id='roadmap-header'/>
            <div id='roadmap-ellipse'/>
            <div id='roadmap-ellipse2'/>
            { (phase === 1) ? <Image roundedCircle className='only-full roadmap-pp' src={pp0} alt="pepe"/> :<></>}
            { (phase === 2) ? <Image roundedCircle className='only-full roadmap-pp' src={pp1} alt="pepe"/> :<></>}
            { (phase === 3) ? <Image roundedCircle className='only-full roadmap-pp' src={pp2} alt="pepe"/> :<></>}
            { (phase === 4) ? <Image roundedCircle className='only-full roadmap-pp' src={pp3} alt="pepe"/> :<></>}
            { (phase === 5) ? <Image roundedCircle className='only-full roadmap-pp' src={pp4} alt="pepe"/> :<></>}
            <h1 id='roadmap-title' className='fw-bold title'>BRAINMAP</h1>

            <div className='only-full my-3'>
                { (phase === 1)
                    ? <button className='roadmap-button focus'>Phase I</button>
                    : <button className='roadmap-button' onClick={() => setPhase(1)}>Phase I</button>
                }
                { (phase === 2)
                    ? <button className='roadmap-button focus'>Phase II</button>
                    : <button className='roadmap-button' onClick={() => setPhase(2)}>Phase II</button>
                }         
                { (phase === 3)
                    ? <button className='roadmap-button focus'>Phase III</button>
                    : <button className='roadmap-button' onClick={() => setPhase(3)}>Phase III</button>
                } 
                { (phase === 4)
                    ? <button className='roadmap-button focus'>Phase IV</button>
                    : <button className='roadmap-button' onClick={() => setPhase(4)}>Phase IV</button>
                }         
                { (phase === 5)
                    ? <button className='roadmap-button focus'>Phase V</button>
                    : <button className='roadmap-button' onClick={() => setPhase(5)}>Phase V</button>
                } 
            </div>

            <Container className="only-reduce mt-5 text-center">
                <ButtonGroup className="only-reduce mt-5 text-center" size="lg">
                    { (phase === 1)
                        ? <Button variant='warning' className='fw-bold'>Phase I</Button>
                        : <Button variant='secondary' className='fw-bold' onClick={() => setPhase(1)}>I</Button>
                    }
                    { (phase === 2)
                        ? <Button variant='warning' className='fw-bold'>Phase II</Button>
                        : <Button variant='secondary' className='fw-bold' onClick={() => setPhase(2)}>II</Button>
                    }         
                    { (phase === 3)
                        ? <Button variant='warning' className='fw-bold'>Phase III</Button>
                        : <Button variant='secondary' className='fw-bold' onClick={() => setPhase(3)}>III</Button>
                    } 
                    { (phase === 4)
                        ? <Button variant='warning' className='fw-bold'>Phase IV</Button>
                        : <Button variant='secondary' className='fw-bold' onClick={() => setPhase(4)}>IV</Button>
                    }         
                    { (phase === 5)
                        ? <Button variant='warning' className='fw-bold'>Phase V</Button>
                        : <Button variant='secondary'className='fw-bold' onClick={() => setPhase(5)}>V</Button>
                    } 
                </ButtonGroup>
            </Container>

            { (phase === 1) ? <Phase1/> :<></>}
            { (phase === 2) ? <Phase2/> :<></>}
            { (phase === 3) ? <Phase3/> :<></>}
            { (phase === 4) ? <Phase4/> :<></>}
            { (phase === 5) ? <Phase5/> :<></>}
       
        </Container>
    </div>

  );
}