import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import frost from "../assets/frost.png";
import skranf from "../assets/skranf.png";
import gabinoux from "../assets/gabinoux.png";
import xthur from "../assets/xthur.png";

function Show(props) {

  const handleClose = () => props.setShow(false);

  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.role}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.titre}</h4>
        <p>{props.text}</p>
      </Modal.Body>
    </Modal>
  );
}

function Item(props){


    return(
        <div style={{position: 'relative'}}>
            
            <button
              style ={{border: "none", backgroundColor: "transparent"}}
              onClick={() => props.setShow(true)}
              className='only-reduce'>
              <img style={{ position: 'relative', zIndex: '1' }} src={props.img} alt="frost"/>
            </button>
            <img className='only-full' style={{ position: 'relative', zIndex: '1' }} src={props.img} alt="frost"/>
            <div
                className='only-full pt-5'
                style={{
                    position: 'absolute',
                    top: "80px",
                    backgroundColor: "rgba(75, 17, 173, 0.4)",
                    borderRadius: "20px",
                }}
            >
                <h2>{props.titre}</h2>
                <p>{props.role}</p>
                <p className='p-4'>{props.text}</p>
            </div>
        </div>
    )
}

const FROST = {
  titre: "Mr Frost",
  role: "FOUNDER",
  text: "Passionate about Web3 and especially NFTs as a consumer, he decided to create his own project after many disappointments in this space, with the goal of offering a project that is worthy of the Web3 community. Here, quality will always take precedence over quantity.",
}

const SKRANF = {
  titre: "Skranf",
  role: "DEVELOPER",
  text: "Passionate about Web3 and especially NFTs as a consumer, he decided to create his own project after many disappointments in this space, with the goal of offering a project that is worthy of the Web3 community. Here, quality will always take precedence over quantity.",
}

const GABINOUX = {
  titre: "Gabinoux",
  role: "COMMUNITY MANAGER",
  text: "Passionate about Web3 and especially NFTs as a consumer, he decided to create his own project after many disappointments in this space, with the goal of offering a project that is worthy of the Web3 community. Here, quality will always take precedence over quantity.",
}

const XTHUR = {
  titre: "Xthur",
  role: "COLLAB MANAGER",
  text: "Passionate about Web3 and especially NFTs as a consumer, he decided to create his own project after many disappointments in this space, with the goal of offering a project that is worthy of the Web3 community. Here, quality will always take precedence over quantity.",
}


export default function Team() {

  const [showFrost, setShowFrost] = useState(false);
  const [showSkranf, setShowSkranf] = useState(false);
  const [showGabinoux, setShowGabinoux] = useState(false);
  const [showXthur, setShowXthur] = useState(false);

  return (
    <div id="team" className='page'>
        <div id="team-1"/>
        <div id="team-2"/>
        <div id="team-3"/>
      <Container className='text-center pt-5'>
        <p className='text pt-5'>MEET THE</p>
        <h1 className='smalltitle'>Belugas Team</h1>
        <Show show={showFrost} setShow={setShowFrost} titre={FROST.titre} role={FROST.role} text={FROST.text}/>
        <Show show={showSkranf} setShow={setShowSkranf} titre={SKRANF.titre} role={SKRANF.role} text={SKRANF.text}/>
        <Show show={showGabinoux} setShow={setShowGabinoux} titre={GABINOUX.titre} role={GABINOUX.role} text={GABINOUX.text}/>
        <Show show={showXthur} setShow={setShowXthur} titre={XTHUR.titre} role={XTHUR.role} text={XTHUR.text}/>
        <Row className="justify-content-md-center pt-5">
          <Col className='m-3'>
            <Item setShow={setShowFrost} img={frost} titre={FROST.titre} role={FROST.role} text={FROST.text}/>
          </Col>
          <Col className='m-3'>
            <Item setShow={setShowSkranf} img={skranf} titre={SKRANF.titre} role={SKRANF.role} text={SKRANF.text}/>
          </Col>
          <Col className='m-3'>
            <Item setShow={setShowGabinoux} img={gabinoux} titre={GABINOUX.titre} role={GABINOUX.role} text={GABINOUX.text}/>
          </Col>
          <Col className='m-3'l>
            <Item setShow={setShowXthur} img={xthur} titre={XTHUR.titre} role={XTHUR.role} text={XTHUR.text}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

//