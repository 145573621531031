import logo from '../assets/logo.png';

export default function Frame(){
    return(
    <div className="frame">
        <div className='frame-line'/>
        
        <div className='frame-box'>
            <span>
            WELCOME TO THE BELUGAS TEMPLE
            <img
                className='frame-img'
                src={logo}
                alt="logo"
                />
            WELCOME TO THE BELUGAS TEMPLE
            <img
                className='frame-img'
                src={logo}
                alt="logo"
                />
            WELCOME TO THE BELUGAS TEMPLE
            <img
                className='frame-img'
                src={logo}
                alt="logo"
                />
            WELCOME TO THE BELUGAS TEMPLE
            <img
                className='frame-img'
                src={logo}
                alt="logo"
                />
            </span>
        </div>
        <div className='frame-line'/>
    </div>
    )
}

/*

*/